import get from 'lodash/get';
import axios from 'axios';
import firebase from '../firebaseInit';
import { PrintElpased } from '../createTimestamps_deorator';
import categoryFormFile from '../data/category.json';
import actionsFormFile from '../data/actions.json';

export * from './seo'

const getToken = async () => await (firebase.auth.currentUser?.getIdToken() || Promise.resolve(null));

export const getProjectOnlyData = async (categoryid, locale) => {
    try {
      // let query = `${process.env.BACK_END_URL 
      //   || process.env.NEXT_PUBLIC_BACK_END_URL}/search?categoryid=${categoryid}&presalestatus=NewCompleted&limit=4`; 
      // if(categoryid === 3) {
      let query = `${process.env.BACK_END_URL 
          || process.env.NEXT_PUBLIC_BACK_END_URL}/search`; 
      // }
      // const res = await fetch(`${process.env.NEXT_PUBLIC_BACK_END_URL}/getProjectListByCateogryID?categoryid=${categoryid}`,
      const res = await fetch(query,
        {
          method: 'POST',
          headers: {
            'Accept-Language': `${locale}`,
            'Content-Type': 'application/json; charset=utf-8'
          },
          body:  JSON.stringify({
            categoryid: categoryid,
            orderby: "project_ID=desc",
            projectonly: true,
            presalestatus: "NewCompleted",
            limit: 4,
            es: true
          })
        }
      )
      const data = await res.json()

      if (!data) {
        return {
          notFound: true,
        }
      }
      
      return data.data;
    } catch (e) {
      return [];
    }
  }

export const getAllProjectOnlyData = async (categoryid, projectname) => {
  try {
    let query = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/search?categoryid=${categoryid}`;
      query = `${process.env.BACK_END_URL 
        || process.env.NEXT_PUBLIC_BACK_END_URL}/search`;
    const res = await fetch(query,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body:  JSON.stringify({
          categoryid: categoryid,
          projectname,
          orderby: "project_ID=desc",
          projectonly: true,
          projectStatus: true,
          limit: 1000,
          es: true
        })
      }
    )
    const data = await res.json()

    if (!data) {
      return {
        notFound: true,
      }
    }
    
    return data.data;
  } catch (e) {
    return [];
  }
}

  
export const getNormal = async (categoryid, url = undefined, locale = 'th') => {
    try {
      const res = await fetch(`${process.env.BACK_END_URL 
        || process.env.NEXT_PUBLIC_BACK_END_URL}/search`,
        {
          method: 'POST',
          headers: {
            'Accept-Language': `${locale}`,
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            categoryid: categoryid,
            sell: true,
            orderby: 'property_Update=desc',
            propertystatus: true,
            limit: 4,
            es: true
          })
        }
      )
      const data = await res.json()
      
      if (!data) {
        return {
          notFound: true,
        }
      }
      return data.data;
    } catch (e) {
      console.log('getNormal: ', e);
      return [];
    }
  }
  
export  const getFacilities = async () => {
    console.log(`getFacilities: `, `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/facilities`)
    try {
      const res = await fetch(`${process.env.BACK_END_URL 
        || process.env.NEXT_PUBLIC_BACK_END_URL}/facilities`)
      const data = await res.json()
      
      if (!data) {
        return {
          notFound: true,
        }
      }
      // console.log(`getFacilities response: `, data.data);

      return data.data;
    } catch (e) {
      console.log(`getFacilities error: `, e);
      return [];
    }
  }

export const getProjectList = async (categoryid, page, limit, orderby, isNewComplete) => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/getProjectListByCateogryID`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          categoryid: categoryid,
          page: page,
          limit: limit,
          orderby,
          isNewComplete
        })
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}


export const getPropertyList = async (categoryid, page, limit, orderby) => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/getNormalListByCateogryID`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          categoryid: categoryid,
          page: page,
          limit: limit,
          orderby
        })
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getPropertyDetail = async (property_ID, project_ID = undefined) => {
  try {

    console.log(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/property/detail/${property_ID}`);
    console.log(`property_ID: ${property_ID}`);
    console.log(`project_id: ${project_ID}`);
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/property/detail/${property_ID}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    // console.log('getPropertyDetail: ', data)
    if (!data) {
      return {
        notFound: true,
      }
    }

    if(data.status === 400) {
      
      return {
        notFound: true,
      }
    }

    return data;
  } catch (e) {
    return [];
  }
}


export const getProjectDetail = async (project_ID = undefined) => {
  try {

    console.log(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/project/detail/${project_ID}`);
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/project/detail/${project_ID}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    // console.log('getPropertyDetail: ', data)
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getCateogryFromFile = async (lang) => {
  return await PrintElpased(`getCateogryFromFile`, async () => {
  try {
    const data = categoryFormFile;

    console.log('getCateogry:  ', data)
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
})
}
export const getCateogry = async (lang) => {
  return await PrintElpased(`getCateogry`, async () => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/category`,
      {
        method: 'GET',
        headers: {
          'Content-Type': "application/json; charset=utf-8",
          ...lang? {
            'Accept-Language': `${lang}`
          }: {}
        }
      }
    )
    const data = await res.json()

    // console.log('getCateogry:  ', data)
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
})
}

export const getActionCateLocationType = async () => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/actions/action-cate-locationtype`,
      {
        method: 'GET',
        headers: {
          'Content-Type': "application/json; charset=utf-8",
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getCategoryByName = async (name: string) => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/category/${name}`;
    console.log(url.trim())
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          contentType: "application/json; charset=utf-8",
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getSubCategoryByID = async (sub_category_id: string) => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/category/sub/${sub_category_id}`;
    console.log(url.trim())
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          contentType: "application/json; charset=utf-8",
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getProvinces = async () => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL }/province`;

    console.log('getProvinces: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()

    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}


export const getProvinceWithAmphur = async (lang: string = 'th') => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL }/province/withamphur?lang=${lang}`;

    console.log('getProvinces: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept-Language': lang 
        }
      }
    )
    const data = await res.json()

    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getProvinceByName = async (name, lang) => {
  return await PrintElpased('getProvinceByName', async () => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/province/${name}`;

    // console.log('getProvinceByName: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          ...lang? {
            'Accept-Language': `${lang}`
          }: {}
        }
      }
    )
    const data = await res.json()

    // console.log('getProvinceByName: ', data)

    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
  })
}

export const getAmphurByProvicneID = async (id) => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/amphur/${id}`;
    console.log('getAmphurByName: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getAmphurByName = async (name) => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/amphur/getByName/${name}`;
    console.log('getAmphurByName: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getDistrictByName = async (name) => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/district/getByName/${name}`;
    console.log('getAmphurByName: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getLocationType = async (lang = 'th') => {
  try {
    const url =`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/locations/types`

    console.log('getLocationType: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept-Language': lang 
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (error) {
    return [];
  }
}
export const getLocationTypeID = async (id) => {
  try {
    const url =`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/locations/types/${id}`

    console.log('getLocationType: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (error) {
    return [];
  }
}

export const getLocationTypeByName = async (name) => {
  return await PrintElpased('getLocationTypeByName', async () => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/locations/isValid?name=${name}`;

    // console.log('getLocationByName: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
  })
}

export const getLocationByName = async (name: string, locationtypeid: string) => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/locations?name=${name}&locationtypeid=${locationtypeid}`;

    console.log('getLocationByName: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getLocationByLocationTypeID = async (locationtypeid: string, lang: string = 'th') => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/locations/location-branches/${locationtypeid}`;

    console.log('getLocationByLocationTypeID: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept-Language': lang 
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getLocationBranchByLocationID = async (locationtypeid: string) => {
  try {
    const url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/locations/branches/${locationtypeid}`;

    console.log('getLocationByLocationTypeID: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
}

export const getLocationBranchByName = async (location: string, branch: string, locationtypeid: string) => {
  return await PrintElpased('getLocationBranchByName', async () => {
  try {
    let url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/locations/branches?location=${location}`;
    url += `&locationtypeid=${locationtypeid}`
    if(branch) {
      url += `&branch=${branch}`;
    }

    // console.log('getLocationBranchByName: ', url);
    
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    return [];
  }
  })
}

export enum SearchType {
  RENT = 'rent',
  ALL = 'all',
  SELL = 'sell'
}

export interface ISearchParameters {
  category?: string;
  categoryid?: string;
  province?: string;
  amphur?: string;
  district?: string;
  country?: string;
  isProject?: boolean;
  startprice?: number;
  endprice?: number;
  page?: number;
  limit?: number; 
  sell?: boolean;
  rent?: boolean;
  projectname?: string;
  propertyname?: string;
  lat?: number;
  lng?: number;
  userid?: string;
  presalestatus?: string;
  orderby?: string;
  projectstatus?: boolean;
  propertystatus?: boolean;
  projectonly?: boolean;
  boots_expire?: string;
  actionid?: number;
  exclusiveIds?: string[];
  locationid?:number;
  locationtypeid?:number;
  locationbranchid?:number;
  subCategoryId?: string;
  propertySizeMin?: number;
  propertySizeMax?: number;
  landSizeMin?: number;
  landSizeMax?: number;
  priceRentMin?: number;
  priceRentMax?: number;
  priceRentDailyMin?: number;
  priceRentDailyMax?: number;
  propertyPriceSqMin?: number;
  propertyPriceSqMax?: number;
  bedRoomMin?:number;
  bedRoomMax?:number;
  propertyUnitMin?: number;
  propertyUnitMax?: number;
  propertyStatus?: number;
  landColorId?: number;
  propertyQuota?: number;
  floorMin?: number;
  floorMax?: number;
  propertyView?: number;
  radiusMin?: number;
  radiusMax?: number;
}

export interface ISearchPropertyParameters {
  propertyid: number
}

export enum SEO_ACTION_FOR_TB_SEO_TITLE {
  รวม = 'รวม',
  ทั้งหมด = 'รวม',
  โครงการ = 'โครงการ',
  ขาย='ซื้อขาย',
  ซื้อขาย='ซื้อขาย',
  เช่า = 'ให้เช่า',
  ให้เช่า = 'ให้เช่า',
  เซ่ง = 'เซ่ง',
  จำนอง = 'จำนอง',
  ขายฝาก= 'ขายฝาก'    
}

export enum SEO_CATEGORY_EN {
  Condo='คอนโด',
  TownhouseHome='ทาวน์เฮาส์-โฮม',
  DetachedHouse='บ้านเดี่ยว',
  HomeOffice='โฮมออฟฟิศ',
  Land='ที่ดิน',
  OfficeSpace='พื้นที่สำนักงาน',
  Apartment='อพาร์ทเมนท์',
  Warehouse='คลังสินค้า',
  CommercialBuilding='อาคารพาณิชย์',
  ShopSpace='ร้านค้า',
  Hotel='โรงแรม',
  Serviceapartment='เซอร์วิสอพาร์ทเมนท์',
  OfficeBuilding='อาคารสำนักงาน',
  Factory='โรงงาน',
  Resort='รีสอร์ท',
  Twinhouse='บ้านแฝด'
}

export enum SEO_CATEGORY_TH {
  คอนโด='คอนโด',
  'ทาวน์เฮาส์-โฮม'='ทาวน์เฮาส์-โฮม',
  'บ้านเดี่ยว'='บ้านเดี่ยว',
  'โฮมออฟฟิศ'='โฮมออฟฟิศ',
  'ที่ดิน'='ที่ดิน',
  'พื้นที่สำนักงาน'='พื้นที่สำนักงาน',
  'อพาร์ทเม้นท์'='อพาร์ทเม้นท์',
  'คลังสินค้า'='คลังสินค้า',
  'อาคารพาณิชย์'='อาคารพาณิชย์',
  'ร้านค้า'='ร้านค้า',
  'โรงแรม'='โรงแรม',
  'เซอร์วิสอพาร์ทเมนท์'='เซอร์วิสอพาร์ทเมนท์',
  'อาคารสำนักงาน'='อาคารสำนักงาน',
  'โรงงาน'='โรงงาน',
  'รีสอร์ท'='รีสอร์ท',
  'บ้านแฝด'='บ้านแฝด'
}

export enum SEO_LEVELS {
  NO_LOCATION ='no location',
  PROVINCE = 'Province',
  DISTRICT = 'District ',
  SUBDISTRICT = 'Subdistrict',
  TRAIN = 'Train',
  TRAIN_LINE = 'Train Line',
  TRAN_STATION = 'Tran Station',
  ROAD = 'Road',
  SOI = 'Soi',
  UNIVERSITY = 'University',
  UNIVERSITY_BRANCH = 'University Branch',
  MALL = 'Mall',
  MALL_BRANCH = 'Mall Branch',
  HOSPITAL = 'Hospital ',
  HOSPITAL_BRANCH = 'Hospital Branch',
  PARK = 'Park',
  AIRPORT = 'Airport',
  HIT_LOCATION_INDUSTRIAL_ZONE = 'Hit Location Industrial Zone',
  HIT_LOCATION_INDUSTRIAL_BEACH ='Hit Location Beach',
  HIT_LOCATION_INDUSTRIAL_GOLF ='Hit Location Golf Course',
  HIT_LOCATION_INDUSTRIAL_CITYHALL ='Hit Location City hall',
  HIT_LOCATION_INDUSTRIAL_BUS_TERMINAL ='Hit Location Bus Terminal',
  HIT_LOCATION_INDUSTRIAL_ZOO ='Hit Location Zoo',
  HIT_LOCATION_INDUSTRIAL_SCHOOL ='Hit Location School',
  HIT_LOCATION_INDUSTRIAL_GOVERNMENT_OFFICE ='Hit Location government office',
  PROJECT_NAME = 'Project name'
}

export enum SEO_LEVELS_NONLOCATION {
  PRICE = 'Price',
  RENTAL_PRICE = 'Rental Price',
  CONDO_TYPE = 'Condo Type',
  QUOTA = 'Quota',
  FLOOR = 'Floor',
  Custom= 'Custom'
}

export enum SEO_LANG {
  TH = 'th',
  EN = 'en',
  CN = 'cn'
}

export const getSearchList = async (parameters: ISearchParameters | ISearchPropertyParameters, abortCon?: AbortController) => {
  console.log("getSearchList - parameter: ", parameters)
  return await PrintElpased('getSearchList', async () => {
    try {
      let options: any =  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          ...parameters,
          es: true
        })
      }
      if(abortCon?.signal) {
        options.signal = abortCon.signal
      }

      const res = await fetch(`${process.env.BACK_END_URL 
        || process.env.NEXT_PUBLIC_BACK_END_URL}/search`,
        options
      )
      const data = await res.json()
      
      if (!data) {
        return {
          notFound: true,
        }
      }
      return data;
    } catch (e) {
      console.log('search error: ', e)
      return [];
    }
  })
}


export const getRecommendData = async (lat: string, lng: string, type: string, provinceid?: string) => {
  return await PrintElpased('getRecommendData', async () => {
  try {

    let url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/recommends?lat=${lat}&lng=${lng}&type=${type}`;
    if(provinceid) {
      url += `&provinceid=${provinceid}`
    }
    console.log(url);
    const res = await fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    // console.log('getRecommendData: ', data)
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log("error", e);
    return undefined;
  }
  })
}

export const getConsolidatedRecommendData = async (lang: string, lat: string, lng: string, provinceid?: string) => {
  let url = `${process.env.BACK_END_URL 
    || process.env.NEXT_PUBLIC_BACK_END_URL}/recommends/bundle?lat=${lat}&lng=${lng}`;

  return await PrintElpased(`getConsolidatedRecommendData: ${url}`, async () => {
  try {

    if(provinceid) {
      url += `&provinceid=${provinceid}`
    }
    // console.log(`getConsolidatedRecommendData: `, url, lang);
    const res = await fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': `${lang}`
        }
      }
    )
    const data = await res.json()
    // console.log('getRecommendData: ', data)
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log("error", e);
    return undefined;
  }
  })
}

export const getSEOTitleAndDesc = async (action: SEO_ACTION_FOR_TB_SEO_TITLE, 
  category: SEO_CATEGORY_EN | SEO_CATEGORY_TH,
  level: SEO_LEVELS | SEO_LEVELS_NONLOCATION, lang: SEO_LANG = SEO_LANG.TH ) => {
  return await PrintElpased('getSEOTitleAndDesc', async () => {
  try {

    // console.log('getSEOTitleAndDesc: ', action, category, level, lang  )
    let url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/seo?action=${action}&category=${category}`;
    if(level != SEO_LEVELS.NO_LOCATION) {
      url += `&level=${level}`;
    }
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8;',
          'Accept-Language': lang
        }
      }
    )

    const contentType = await res.headers.get('content-type');

    const data = await res.json();
    // console.log('getRecommendData: ', data)
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log("error", e);
    return undefined;
  }})
}

export const getActionsFromFile = async (lang: string) => {
  return await PrintElpased(`getActionsFromFile ${`${process.env.BACK_END_URL 
    || process.env.NEXT_PUBLIC_BACK_END_URL}/actions`}`, async () => {
    try {
      
      const data = actionsFormFile[lang];

      if (!data) {
        return {
          notFound: true,
        }
      }
      return data;
    } catch (e) {
      console.log('search error: ', e)
      return [];
    }
  })
}
export const getActions = async (lang: string) => {
  return await PrintElpased(`getActions ${`${process.env.BACK_END_URL 
    || process.env.NEXT_PUBLIC_BACK_END_URL}/actions`}`, async () => {
    try {
      const res = await fetch(`${process.env.BACK_END_URL 
        || process.env.NEXT_PUBLIC_BACK_END_URL}/actions`,
        {
          method: 'GET',
          headers: {
            'Accept-Language': lang,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
      const data = await res.json()
      
      if (!data) {
        return {
          notFound: true,
        }
      }
      return data;
    } catch (e) {
      console.log('search error: ', e)
      return [];
    }
  })
}

export interface IRequestAutoSEOContent {
  lang: string;
    action_id: string;
    
    province_id: string;
    amphur_id: string;
    district_id: string;

    category_id: string;
    group_category_name: string;
    sub_category_lv1_name: string;
    sub_category_lv2_name: string;
    
    location_type_id: string;
    location_id: string;
    location_branch_id: string;
    count: number;
    projectname: string;
    project_id: string;
}
export const getseoAutoContent = async ({
    lang = 'TH',
    action_id = undefined,
    category_id = undefined,
    province_id = undefined,
    district_id = undefined,
    
    group_category_name = undefined,
    sub_category_lv1_name = undefined,
    sub_category_lv2_name = undefined,

    location_type_id = undefined,
    location_id = undefined,
    location_branch_id = undefined,
    amphur_id = undefined, 
    count = 0,
    projectname = undefined,
    project_id = undefined
 }: IRequestAutoSEOContent) => {
  return await PrintElpased('getseoAutoContent', async () => {
  try {

    // console.log('getseoAutoContent [lang]: ', lang,
    //   action_id,
    //   category_id,
    //   province_id,
    //   location_id
    //   )
    
    if(project_id === undefined 
      && province_id == undefined && location_type_id == undefined){
      
      // if(category_id === undefined) {
      return null;
      // }
    }

    let url = `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/seo/autocontent?category_id=${category_id || 0}`;
    if(action_id)
      url += `&action_id=${action_id}`;
    
    if(province_id)
      url += `&province_id=${province_id}`;
    
    if(location_type_id)
      url += `&location_type_id=${location_type_id}`;
    
    if(location_id)
      url += `&location_id=${location_id}`;

    if(location_branch_id)
      url += `&location_branch_id=${location_branch_id}`;

    if(district_id)  url += `&district_id=${district_id}`;
    if(amphur_id)  url += `&amphur_id=${amphur_id}`;

    if(group_category_name) url += `&group_category_name=${group_category_name}`;
    if(sub_category_lv1_name)  url += `&sub_category_lv1_name=${sub_category_lv1_name}`;
    if(sub_category_lv2_name)  url += `&sub_category_lv2_name=${sub_category_lv2_name}`;

    if(projectname)  url += `&projectname=${projectname}`;
    if(project_id)  url += `&project_id=${project_id}`;

    // console.log('getseoAutoContent: ', url);

    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json();
    const template = get(data, 'data[0].template');
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return null;
  }
  })
}

export const getActionsByName = async (lang: string, name: string) => {
  try {
    const res = await fetch(encodeURI(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/actions/getByName?name=${name}`),
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return [];
  }
}

export const getChangeLanguageData = async (lang: string, params: any) => {
  try {
    const query = `?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}`;
    console.log('query getChangeLanguageData: ', query)

    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/language/change_data${query}`,
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return [];
  }
}

export const getAgentByPropertyId = async (id: number) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/agent/by/property?id=${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const data = await res.json()

    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAgentByProjectId = async (id: number) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/agent/by/project?id=${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const data = await res.json()

    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const handleCallAction = async (
  type: string,
  id: number,
  buyerId: number,
) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/call?type=${type}&id=${id}&buyerId=${buyerId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const data = await res.json()

    return data
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const handleInquiryActivity = async (
  type: string,
  id: number,
  buyerId: number,
  message: string,
) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/inquiry?type=${type}&id=${id}&buyerId=${buyerId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          message,
        }),
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const getAppointedSlots = async (
  type: string,
  id: number,
  buyerId: number,
  initialAppointedSlots: {start:Date,end:Date}[],
) => {
  try {
    const res = await fetch(
      `${process.env.BACK_END_URL ||
      process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/appointment?type=${type}&id=${id}&buyerId=${buyerId}&initialAppointedSlots=${initialAppointedSlots.map((slot) => `${slot.start.toUTCString()}-${slot.end.toUTCString()}`).join('|')}
        `,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const handleAppointmentActivity = async (
  type: string,
  id: number,
  buyerId: number,
  dateTimeStart: Date,
  dateTimeEnd: Date,
  appointedXLon: number,
  appointedYLat: number,
  appointedVenue: string,
) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/appointment?type=${type}&id=${id}&buyerId=${buyerId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          dateTimeStart,
          dateTimeEnd,
          timezone: new Date().getTimezoneOffset() / 60,
          appointedXLon,
          appointedYLat,
          appointedVenue,
        }),
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const handleGetAppointmentAvailable = async (
  type: string,
  id: number,
  buyerId: number,
) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/appointmentavaliabled?type=${type}&id=${id}&buyerId=${buyerId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const getFavorite = async (
  type: string,
  id: number,
  buyerId: number,
) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/favorite?type=${type}&id=${id}&buyerId=${buyerId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const handleFavoriteActivity = async (
  type: string,
  id: number,
  buyerId: number,
) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/favorite?type=${type}&id=${id}&buyerId=${buyerId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const handleUnFavoriteActivity = async (
  type: string,
  id: number,
  buyerId: number,
) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/favorite?type=${type}&id=${id}&buyerId=${buyerId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const handleViewActivity = async (
  type: string,
  id: number,
  buyerId: number,
) => {
  try {
    const res = await fetch(
      `${
        process.env.BACK_END_URL ||
        process.env.NEXT_PUBLIC_BACK_END_URL
      }/activity/view?type=${type}&id=${id}&buyerId=${buyerId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const getSubGroupCategory = async (cateId: String) => {
  try {
    const token = localStorage.getItem(`9asset_token`);
    const res = await fetch(
      `${
        process.env.NEXT_PUBLIC_BUYER_SERVICE_URL
      }/category/subgroup/${cateId}`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const addRequirement = async (token: string | null, payload) => {
  try {
    if(!token) {
      return {
        error: true,
        message: 'login required',
      }
    }
    console.log(token)
    const res = await fetch(
      `${
        process.env.NEXT_PUBLIC_BUYER_SERVICE_URL
      }/requirements/add`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const getActionsRequirement = async (lang: string) => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/actions-requirement`,
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return [];
  }
}

export const getTypeRequirement = async (lang: string) => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/type-requirement`,
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return [];
  }
}

export const getWelcomeRequirement = async (lang: string) => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/welcome-requirement`,
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return [];
  }
}

export const getPurpose = async (lang: string) => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/purpose`,
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return [];
  }
}

export const getPrivacyPolicy = async (lang: string) => {
  try {
    const url = `${process.env.BACK_END_URL || process.env.NEXT_PUBLIC_BACK_END_URL}/privacy_policy`;
    console.log('get Privacy policy ', url);
    const res = await fetch(encodeURI(url),
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    console.log('get Privacy policy ', res);
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return [];
  }
}


export const getArticleCategories = async (page:number, limit:number, query?:string) => {
  const end_point =  `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/article/all/category`

  const url = new URL(
    end_point
  ) 
  if (query) url.searchParams.append('q', query.toString())
  if (page) url.searchParams.append('page', page.toString())
  if (limit) url.searchParams.append('limit', limit.toString())

  try {
    return axios.get(url.toString())
        .then((res) => {
          const { data } = res?.data;
          return data
        })
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}


export const getArticles = async (page:number, limit:number, query?:string, category_id?:number, start_date?:string, end_date?:string) => {
  const end_point =  `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/article`
  const url = new URL(
    end_point
  ) 
  if (query) url.searchParams.append('q', query.toString())
  if (page >= 0) url.searchParams.append('page', page.toString())
  if (limit) url.searchParams.append('limit', limit.toString())
  if (category_id) url.searchParams.append('category_id', category_id.toString())
  if (start_date) url.searchParams.append('start_date', start_date.toString())
  if (end_date) url.searchParams.append('end_date', end_date.toString())
  
  url.searchParams.append('status','1')

  try {
    return axios.get(url.toString())
        .then((res) => {
          const { data } = res?.data;
          return data
        })
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const getArticleDetail = async (id:number, lng?:string) => {
  const end_point =  `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/article/${id}?lng=${lng}`

  const url = new URL(
    end_point
  ) 

  try {
    return axios.get(url.toString())
        .then((res) => {
          const { data } = res?.data;
          return data
        })
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}

export const getArticleParagraph = async (newsId:number) => {
  const end_point =  `${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/article/get/paragraph/${newsId}`

  const url = new URL(
    end_point
  ) 

  try {
    return axios.get(url.toString())
        .then((res) => {
          const { data } = res?.data;
          return data
        })
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}



export const getTermsConditionsDetail = async (lang: string) => {
  try {
    const res = await fetch(`${process.env.BACK_END_URL 
      || process.env.NEXT_PUBLIC_BACK_END_URL}/terms`,
      {
        method: 'GET',
        headers: {
          'Accept-Language': lang,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    )
    const data = await res.json()
    
    if (!data) {
      return {
        notFound: true,
      }
    }
    return data;
  } catch (e) {
    console.log('search error: ', e)
    return [];
  }
}

export const saveProjectProperty = async (token: string | null, payload: {
  rq_id: string
  project_name_TH: string
  project_name_EN: string
  project_name_CN: string
  project_id: number | undefined
}) => {
  try {
    const token = await getToken();
    if(!token) {
      return {
        error: true,
        message: 'login required',
      }
    }
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_BUYER_SERVICE_URL}/requirements/save-project`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      },
    )
    if (res.status !== 200) {
      console.error(res)
      return {
        error: true,
        message: 'data not found',
      }
    }

    const { data } = await res.json()

    return { data }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: `error: ${error}`,
    }
  }
}
